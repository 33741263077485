import { Logo, LogoIcon } from "components/logo"
import { Subtitle1 } from "components/typography"
import { routerActions } from "connected-react-router"
import { SearchBar } from "domain/search/components/searchbar"
import React from "react"
import { directionalBoxShadow } from "style/directional-dropshadow"
import { ThemeProps } from "style/theme"
import styled from "styled-components"
import { HomeRoute, Levels } from "../../../constants"
import { useStore } from "react-redux"
import { RootState } from "typings"

const StyledLogo = styled(Logo)`
  cursor: pointer;
  padding: 0 8px;
  width: 150px;
  height: 100%;
`

const StyledLogoIcon = styled(LogoIcon)`
  cursor: pointer;
  padding: 0 8px;
  height: 100%;
`

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  gap: 8px;
  padding: 8px;
  align-items: center;
  background: ${({ theme }: ThemeProps): string => `${theme.background.color}77`};
  z-index: ${Levels.ElevatedContent};
  ${directionalBoxShadow("bottom", "large")}

  ${StyledLogoIcon} {
    display: none;
  }

  @media screen and (max-width: 550px) {
    ${StyledLogoIcon} {
      display: initial;
    }
    ${StyledLogo} {
      display: none;
    }
  }
`

// const StyledCollapseButton = styled(MdIcon).attrs({ icon: "menu" })`
//   color: ${({ theme }: ThemeProps): string => theme.surface.textOn};
//   cursor: pointer;
//   padding: 4px;

//   :hover {
//     color: white;
//   }
// `

const StyledBetaWarning = styled(Subtitle1)`
  font-weight: bold;
  color: red;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @media screen and (max-width: 550px) {
    display: none;
  }
`

export const Header = (props: HeaderProps): JSX.Element => {
  const store = useStore<RootState>()
  // const onCollapseClicked = (): void => {
  //   store.dispatch(navbarActions.toggleNavbarMode())
  // }

  const onLogoClicked = (): void => {
    store.dispatch(routerActions.push(HomeRoute))
  }

  return (
    <StyledHeader {...props}>
      {/* <StyledCollapseButton onClick={onCollapseClicked} /> */}
      <StyledLogo onClick={onLogoClicked} />
      <StyledLogoIcon onClick={onLogoClicked} />
      <SearchBar />
      <StyledBetaWarning></StyledBetaWarning>
      <span />
    </StyledHeader>
  )
}

export type HeaderProps = React.HTMLAttributes<HTMLDivElement>
